/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background: #11101d;
    z-index: 100;
    transition: all 0.5s ease;
}

.sidebar.close {
    width: 52px;
}

.sidebar .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}

.sidebar .logo-details i {
    font-size: 30px;
    color: #fff;
    height: 50px;
    min-width: 52px;
    text-align: center;
    line-height: 50px;
}

.sidebar .logo-details .logo_name {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links {
    height: 100%;
    padding: 0 0 150px 0;
    overflow: auto;
}


.sidebar.close .nav-links {
    overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
    display: none;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li:hover,
.sidebar .nav-links li.active {
    background: #1d1b31;
}

.sidebar .nav-links li .iocn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
    display: block
}

.sidebar .nav-links li i {
   
    text-align: center;
    
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}
.sidebar .nav-links li i:not(.bxs-chevron-down){
    height: 50px;
    min-width: 52px;
    line-height: 50px;
    font-size: 20px;
    
}

.sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
    display: none;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar .nav-links li a .link_name {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    transition: all 0.4s ease;
}

.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 30px;
    margin-top: -10px;
    background: #1d1b31;
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu,
.sidebar.close .nav-links li.active .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank,
.sidebar .nav-links li.active .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
}

.sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details {
    background: none;
}

.sidebar.close .profile-details {
    width: 52px;
}

.sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
    padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
    display: none;
}

.sidebar .profile-details .job {
    font-size: 12px;
}



.sidebar .user-detail {
    display: flex;
    align-items: center;
    /* position: fixed;
    bottom: 0; */
    /* width: 260px; */
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* background: #1d1b31; */
    padding: 12px 0;
    transition: all 0.5s ease;
    color: #fff;
    cursor: pointer;
    user-select: none;
}

.sidebar .user-detail a {
    text-decoration: none;
    color: #fff;
}

.sidebar .user-detail .icon {
    display: inline-block;
    margin-left: 20px;
    margin-right: 5px;
}

.sidebar .user-detail.logout {

    position: fixed;
    bottom: 0;
    /* width: 260px; */
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* background: #1d1b31; */
    padding: 12px 0;
    transition: all 0.5s ease;
    color: #fff;
}

.sidebar.close .user-detail span.text {
    display: none;
}


.sidebar:not(.close) .user-detail a{
    min-width: 260px !important;
    display: flex;
    /* align-items: center; */
}

.bxs-chevron-down{
    width: 25px !important;
}




.home-section {
    position: relative;
    background: #E4E9F7;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
}

.sidebar.close~.home-section {
    left: 52px;
    width: calc(100% - 52px);
}

.home-section .home-content {
    height: 60px;
    display: flex;
    align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
    color: #11101d;
    font-size: 25px;
}

.home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
}

.home-section .home-content .text {
    font-size: 26px;
    font-weight: 600;
}

@media (max-width: 600px) {
    .sidebar {
        transition: all 0s ease;
        max-height: 100vh !important;
    }

    .home-section {
        transition: all 0s ease;
    }

    .sidebar.mobile-hide {
        width: 0;
    }

    .sidebar.mobile-hide~.home-section {
        width: 100%;
        left: 0;
    }

    .sidebar.mobile-hide {
        display: none;
    }

    .sidebar .user-detail .icon {
        display: inline-block;
        margin: 0;
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sidebar .user-detail span.text {
        display: none;
    }

}