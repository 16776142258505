.custom-table{
    overflow: auto;
}
.custom-table table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    /* table-layout: fixed; */
}

.custom-table table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.custom-table table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
}

.custom-table table th,
.custom-table table td {
    padding: .625em;
    text-align: left;
}

.custom-table table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.custom-table .table-form-check .form-check{
    width: 20px;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .custom-table table {
        border: 0;
    }

    .custom-table table caption {
        font-size: 1.3em;
    }

    .custom-table table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

    }

    .custom-table table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .custom-table table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .custom-table table td::before {
        /*
	  * aria-label has no advantage, it won't be read inside a table
	  content: attr(aria-label);
	  */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .custom-table table td:last-child {
        border-bottom: 0;
    }

    .custom-table .table-form-check .form-check{
        margin-left: auto;
        margin-right: 0px;
    }
    
}