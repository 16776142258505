#navbar_container {
    margin-bottom: 60px;
}
nav.bg-dark,.dropdown-menu{
    background-color: #3b4652 !important;
}
.principal-card{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 20px 0;
    border: none !important;
}

.required_indicator {
    color: red;
    margin-left: 2px;
}

.form-errors span {
    color: red;
}

.contenedor-padre{
    max-width: 100%;
}
.contenedor {
    padding: 25px 10px;
}

.error-message{
    border: 1px solid red;
    border-left-width: 5px;
    padding: 2px 5px;
    margin: 3px;
}

.logout-btn{
    cursor: pointer;
}
.password-input-container{
    position: relative;
}

.show-hide-password-icon{
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}


@media screen and (min-width:768px) {
    .md_check {
        margin-top: 25px;
    }
}

@media screen and (max-width:768px) {
    .principal-card{
        padding: 2px;
    }
}

.pagination-container{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    gap: 5px;
}

.pagination-btn{
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(10, 103, 195);
    color: #fff;
    user-select: none !important;
}
.pagination-btn.active{
    background-color: #0d6efd;
}
.pagination-btn.disabled{
    background-color: #4a72ad;
}
.pagination-btn-anterior{
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}
.pagination-btn-siguiente{
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}
.ck-editor__editable{
    min-height: 300px;
}


/* Imagenes inicio */
.image-container{
    border: 1px solid #f1f1f1;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.image-container .image{
    width: 100% !important;
}
.image-container .image-delete-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
.image-container svg{
    
    font-size: 20px;
    
    font-weight: 800;
    color: red;
    cursor: pointer;
    z-index: 2;
}


/* .image-input-container input{
    display: none;
} */
.image-input-container .upload-label-icon{
    font-size: 50px;
}
.image-input-container img{
    width: 200px !important;
}
.image-list{
    display: flex;
    justify-content: start;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: wrap;
}

@media  screen and (max-width:520px) {
    .image-container{
        width: 100%;
        height:auto;
        margin: 0 auto;
    }
}

/* Imagenes fin */

 /* Carroussel .imagen  */
@media screen and (min-width: 768px) {
    .image-gallery-slide .image-gallery-image{
        object-fit: contain;
        max-height: calc(100vh - 300px) !important;
     }
    
}